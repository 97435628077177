import * as analytics from '@vercel/analytics'
import * as speed from '@vercel/speed-insights'
import { main } from './admin.gleam'

// Setup Vercel toolings.
analytics.inject({ mode: import.meta.env.DEV ? 'development' : 'production' })
speed.injectSpeedInsights()

// Start the Lustre app.
document.addEventListener('DOMContentLoaded', main)
