/// <reference types="./tardis.d.mts" />
import * as $dynamic from "../gleam_stdlib/gleam/dynamic.mjs";
import * as $function from "../gleam_stdlib/gleam/function.mjs";
import * as $int from "../gleam_stdlib/gleam/int.mjs";
import * as $io from "../gleam_stdlib/gleam/io.mjs";
import * as $list from "../gleam_stdlib/gleam/list.mjs";
import * as $option from "../gleam_stdlib/gleam/option.mjs";
import { Some } from "../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../gleam_stdlib/gleam/result.mjs";
import * as $lustre from "../lustre/lustre.mjs";
import * as $a from "../lustre/lustre/attribute.mjs";
import * as $effect from "../lustre/lustre/effect.mjs";
import * as $el from "../lustre/lustre/element.mjs";
import * as $h from "../lustre/lustre/element/html.mjs";
import * as $event from "../lustre/lustre/event.mjs";
import * as $sketch from "../sketch/sketch/lustre.mjs";
import * as $sketch_options from "../sketch/sketch/options.mjs";
import { toList, CustomType as $CustomType, isEqual } from "./gleam.mjs";
import * as $error from "./tardis/error.mjs";
import * as $colors from "./tardis/internals/data/colors.mjs";
import * as $debugger_ from "./tardis/internals/data/debugger.mjs";
import * as $model from "./tardis/internals/data/model.mjs";
import { Model } from "./tardis/internals/data/model.mjs";
import * as $msg from "./tardis/internals/data/msg.mjs";
import * as $setup from "./tardis/internals/setup.mjs";
import * as $s from "./tardis/internals/styles.mjs";
import * as $v from "./tardis/internals/view.mjs";

class Tardis extends $CustomType {
  constructor(dispatch) {
    super();
    this.dispatch = dispatch;
  }
}

class Instance extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export function activate(result, instance) {
  return $result.map(
    result,
    (dispatch) => {
      let dispatcher = instance[0][0];
      dispatcher($dynamic.from(dispatch));
      return dispatch;
    },
  );
}

function start_lustre(lustre_root, application) {
  let _pipe = application;
  let _pipe$1 = $lustre.start(_pipe, lustre_root, undefined);
  return $result.map_error(
    _pipe$1,
    (var0) => { return new $error.LustreError(var0); },
  );
}

function start_sketch(root) {
  let setup$1 = $sketch.setup($sketch_options.shadow(root));
  return $result.map_error(
    setup$1,
    (error) => {
      $io.debug("Unable to start sketch. Check your configuration.");
      $io.debug(error);
      return new $error.SketchError(error);
    },
  );
}

function init(_) {
  let _pipe = $colors.choose_color_scheme();
  let _pipe$1 = ((_capture) => {
    return new Model(toList([]), _capture, false, false, new $option.None());
  })(_pipe);
  return $pair.new$(_pipe$1, $effect.none());
}

function update(model, msg) {
  if (msg instanceof $msg.ToggleOpen) {
    return [model.withFields({ opened: !model.opened }), $effect.none()];
  } else if (msg instanceof $msg.Restart) {
    let debugger_ = msg[0];
    let restart_effect = (() => {
      let _pipe = model.debuggers;
      let _pipe$1 = $list.filter_map(
        _pipe,
        (d_) => {
          let d = $pair.second(d_);
          let fst_step = $list.first(d.steps);
          return $result.try$(
            fst_step,
            (step) => {
              let _pipe$1 = d.dispatcher;
              let _pipe$2 = $option.map(
                _pipe$1,
                (_capture) => { return $function.apply1(_capture, step.model); },
              );
              return $option.to_result(_pipe$2, undefined);
            },
          );
        },
      );
      return $effect.batch(_pipe$1);
    })();
    let _pipe = model.debuggers;
    let _pipe$1 = $debugger_.replace(_pipe, debugger_, $debugger_.unselect);
    let _pipe$2 = ((ds) => {
      return model.withFields({ frozen: false, debuggers: ds });
    })(_pipe$1);
    return $pair.new$(_pipe$2, restart_effect);
  } else if (msg instanceof $msg.UpdateColorScheme) {
    let cs = msg[0];
    let _pipe = model.withFields({ color_scheme: cs });
    return $pair.new$(_pipe, $colors.save_color_scheme(cs));
  } else if (msg instanceof $msg.AddApplication) {
    let debugger_ = msg[0];
    let dispatcher = msg[1];
    let _pipe = model.debuggers;
    let _pipe$1 = $debugger_.replace(
      _pipe,
      debugger_,
      (_capture) => { return $debugger_.add_dispatcher(_capture, dispatcher); },
    );
    let _pipe$2 = ((d) => { return model.withFields({ debuggers: d }); })(
      _pipe$1,
    );
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.BackToStep) {
    let debugger_ = msg[0];
    let item = msg[1];
    let selected_step = new $option.Some(item.index);
    let model_effect = (() => {
      let _pipe = model.debuggers;
      let _pipe$1 = $debugger_.get(_pipe, debugger_);
      let _pipe$2 = $result.try$(
        _pipe$1,
        (d) => {
          let _pipe$2 = d.dispatcher;
          let _pipe$3 = $option.map(
            _pipe$2,
            (_capture) => { return $function.apply1(_capture, item.model); },
          );
          return $option.to_result(_pipe$3, undefined);
        },
      );
      return $result.unwrap(_pipe$2, $effect.none());
    })();
    let _pipe = model.debuggers;
    let _pipe$1 = $debugger_.replace(
      _pipe,
      debugger_,
      (_capture) => { return $debugger_.select(_capture, selected_step); },
    );
    let _pipe$2 = ((d) => {
      return model.withFields({ frozen: true, debuggers: d });
    })(_pipe$1);
    return $pair.new$(_pipe$2, model_effect);
  } else if (msg instanceof $msg.Debug) {
    let value = msg[0];
    $io.debug(value);
    return [model, $effect.none()];
  } else if (msg instanceof $msg.SelectDebugger) {
    let debugger_ = msg[0];
    let _pipe = model.withFields({
      selected_debugger: new $option.Some(debugger_)
    });
    return $pair.new$(_pipe, $effect.none());
  } else {
    let debugger_ = msg[0];
    let m = msg[1];
    let m_ = msg[2];
    let _pipe = model.debuggers;
    let _pipe$1 = $debugger_.replace(
      _pipe,
      debugger_,
      (_capture) => { return $debugger_.add_step(_capture, m, m_); },
    );
    let _pipe$2 = ((d) => { return model.withFields({ debuggers: d }); })(
      _pipe$1,
    );
    let _pipe$3 = $model.optional_set_debugger(_pipe$2, debugger_);
    return $pair.new$(_pipe$3, $effect.none());
  }
}

function select_panel_options(panel_opened) {
  if (panel_opened) {
    return [$s.panel(), $s.bordered_header(), "Close"];
  } else {
    return [$s.panel_closed(), $s.header(), "Open"];
  }
}

function on_cs_input(content) {
  let cs = $colors.cs_from_string(content);
  return new $msg.UpdateColorScheme(cs);
}

function on_debugger_input(content) {
  return new $msg.SelectDebugger(content);
}

function color_scheme_selector(model) {
  let $ = model.opened;
  if (!$) {
    return $el.none();
  } else {
    return $h.select(
      toList([$event.on_input(on_cs_input), $s.select_cs()]),
      $list.map(
        $colors.themes(),
        (item) => {
          let as_s = $colors.cs_to_string(item);
          let selected = isEqual(model.color_scheme, item);
          return $h.option(
            toList([$a.value(as_s), $a.selected(selected)]),
            as_s,
          );
        },
      ),
    );
  }
}

function restart_button(model) {
  let $ = model.frozen;
  let $1 = model.selected_debugger;
  if ($ && $1 instanceof Some) {
    let debugger_ = $1[0];
    return $h.button(
      toList([$s.select_cs(), $event.on_click(new $msg.Restart(debugger_))]),
      toList([$h.text("Restart")]),
    );
  } else {
    return $el.none();
  }
}

function view(model) {
  let color_scheme_class = $colors.get_color_scheme_class(model.color_scheme);
  let $ = select_panel_options(model.opened);
  let panel = $[0];
  let header = $[1];
  let button_txt = $[2];
  let frozen_panel = (() => {
    let $1 = model.frozen;
    if ($1) {
      return $s.frozen_panel();
    } else {
      return $a.none();
    }
  })();
  let debugger_ = (() => {
    let _pipe = model.selected_debugger;
    let _pipe$1 = $option.unwrap(_pipe, "");
    return ((_capture) => { return $debugger_.get(model.debuggers, _capture); })(
      _pipe$1,
    );
  })();
  return $h.div(
    toList([$a.class$("debugger_"), color_scheme_class, frozen_panel]),
    toList([
      $h.div(
        toList([panel]),
        toList([
          $h.div(
            toList([header]),
            toList([
              $h.div(
                toList([$s.flex(), $s.debugger_title()]),
                toList([
                  $h.div(toList([]), toList([$h.text("Debugger")])),
                  color_scheme_selector(model),
                  restart_button(model),
                ]),
              ),
              (() => {
                if (!debugger_.isOk()) {
                  return $el.none();
                } else {
                  let debugger_$1 = debugger_[0];
                  return $h.div(
                    toList([$s.actions_section()]),
                    toList([
                      $h.select(
                        toList([
                          $event.on_input(on_debugger_input),
                          $s.select_cs(),
                        ]),
                        $list.map(
                          $model.keep_active_debuggers(model),
                          (_use0) => {
                            let item = _use0[0];
                            let selected = isEqual(
                              model.selected_debugger,
                              new Some(item)
                            );
                            return $h.option(
                              toList([$a.value(item), $a.selected(selected)]),
                              item,
                            );
                          },
                        ),
                      ),
                      $h.div(
                        toList([]),
                        toList([
                          $h.text(
                            $int.to_string(debugger_$1.count - 1) + " Steps",
                          ),
                        ]),
                      ),
                      $h.button(
                        toList([
                          $s.toggle_button(),
                          $event.on_click(new $msg.ToggleOpen()),
                        ]),
                        toList([$h.text(button_txt)]),
                      ),
                    ]),
                  );
                }
              })(),
            ]),
          ),
          (() => {
            let $1 = model.selected_debugger;
            if (debugger_.isOk() && $1 instanceof Some) {
              let debugger_$1 = debugger_[0];
              let d = $1[0];
              return $v.view_model(model.opened, d, debugger_$1);
            } else {
              return $el.none();
            }
          })(),
        ]),
      ),
    ]),
  );
}

export function setup() {
  let $ = $setup.mount_shadow_node();
  let shadow_root = $[0];
  let lustre_root = $[1];
  let _pipe = start_sketch(shadow_root);
  let _pipe$1 = $result.map(
    _pipe,
    (_capture) => { return $sketch.compose(view, _capture); },
  );
  let _pipe$2 = $result.map(
    _pipe$1,
    (_capture) => { return $lustre.application(init, update, _capture); },
  );
  let _pipe$3 = $result.try$(
    _pipe$2,
    (_capture) => { return start_lustre(lustre_root, _capture); },
  );
  return $result.map(_pipe$3, (dispatch) => { return new Tardis(dispatch); });
}

export function wrap(application, instance) {
  let middleware = instance[0][1];
  return $setup.update_lustre(
    application,
    $setup.wrap_init(middleware),
    $setup.wrap_update(middleware),
  );
}

export function application(instance, name) {
  let dispatch = instance.dispatch;
  let updater = $setup.create_model_updater(dispatch, name);
  let adder = $setup.step_adder(dispatch, name);
  return new Instance([updater, adder]);
}

export function single(name) {
  let _pipe = setup();
  return $result.map(
    _pipe,
    (_capture) => { return application(_capture, name); },
  );
}
