import data/model.{type Model}
import data/msg.{type Msg}
import gleam/int
import gleam/result
import lustre
import lustre/effect
import lustre/element/html
import lustre/event
import tardis
import vitools

pub fn main() {
  let debugger_ = case vitools.is_dev() {
    False -> Error(Nil)
    True -> tardis.single("Admin") |> result.nil_error()
  }

  let apply_debugger = fn(app: a, fun: fn(a, tardis.Instance) -> a) {
    result.map(debugger_, fun(app, _)) |> result.unwrap(app)
  }

  let assert Ok(_dispatch) =
    lustre.application(init, update, view)
    |> apply_debugger(tardis.wrap)
    |> lustre.start("#app", Nil)
    |> apply_debugger(tardis.activate)
}

fn init(_flags) {
  #(model.new(), effect.none())
}

fn update(model: Model, msg: Msg) {
  case msg {
    msg.Increment -> #(model.Model(model.count + 1), effect.none())
  }
}

fn view(model: Model) {
  html.div([], [
    html.text("Hello world"),
    html.text(int.to_string(model.count)),
    html.button([event.on_click(msg.Increment)], [html.text("Increment")]),
  ])
}
