/// <reference types="./colors.d.mts" />
import * as $result from "../../../../gleam_stdlib/gleam/result.mjs";
import * as $effect from "../../../../lustre/lustre/effect.mjs";
import * as $storage from "../../../../plinth/plinth/javascript/storage.mjs";
import * as $sketch from "../../../../sketch/sketch.mjs";
import { toList, CustomType as $CustomType } from "../../../gleam.mjs";

export class Colors extends $CustomType {
  constructor(background, shadow, primary, editor_fg, editor_bg, gutter, syntax_comment, button, function$, nil, bool, constant, bit_array, utf_codepoint, string, number, custom_type, regex, date) {
    super();
    this.background = background;
    this.shadow = shadow;
    this.primary = primary;
    this.editor_fg = editor_fg;
    this.editor_bg = editor_bg;
    this.gutter = gutter;
    this.syntax_comment = syntax_comment;
    this.button = button;
    this.function = function$;
    this.nil = nil;
    this.bool = bool;
    this.constant = constant;
    this.bit_array = bit_array;
    this.utf_codepoint = utf_codepoint;
    this.string = string;
    this.number = number;
    this.custom_type = custom_type;
    this.regex = regex;
    this.date = date;
  }
}

export class AyuDark extends $CustomType {}

export class AyuLight extends $CustomType {}

export class CatpuccinLight extends $CustomType {}

export class CatpuccinFrappe extends $CustomType {}

export class Gleam extends $CustomType {}

export function cs_to_string(color_scheme) {
  if (color_scheme instanceof AyuDark) {
    return "Ayu Dark";
  } else if (color_scheme instanceof AyuLight) {
    return "Ayu Light";
  } else if (color_scheme instanceof CatpuccinLight) {
    return "Catpuccin Light";
  } else if (color_scheme instanceof CatpuccinFrappe) {
    return "Catpuccin Frappé";
  } else {
    return "Gleam";
  }
}

export function cs_from_string(key) {
  if (key === "Ayu Dark") {
    return new AyuDark();
  } else if (key === "Ayu Light") {
    return new AyuLight();
  } else if (key === "Catpuccin Light") {
    return new CatpuccinLight();
  } else if (key === "Catpuccin Frappé") {
    return new CatpuccinFrappe();
  } else if (key === "Gleam") {
    return new Gleam();
  } else {
    return new Gleam();
  }
}

export function themes() {
  return toList([
    new AyuDark(),
    new AyuLight(),
    new CatpuccinLight(),
    new CatpuccinFrappe(),
    new Gleam(),
  ]);
}

const settings_key = "lustre-debugger-color";

export function choose_color_scheme() {
  let _pipe = $storage.local();
  let _pipe$1 = $result.try$(
    _pipe,
    (_capture) => { return $storage.get_item(_capture, settings_key); },
  );
  let _pipe$2 = $result.map(_pipe$1, cs_from_string);
  return $result.unwrap(_pipe$2, new Gleam());
}

export function save_color_scheme(color_scheme) {
  return $effect.from(
    (_) => {
      let $ = $result.try$(
        $storage.local(),
        (local) => {
          let cs_s = cs_to_string(color_scheme);
          return $storage.set_item(local, settings_key, cs_s);
        },
      );
      
      return undefined;
    },
  );
}

export const ayu_dark = new Colors(
  "#111",
  "#333",
  "#ffcc66",
  "#cccac2",
  "#242936",
  "#8a919966",
  "#b8cfe680",
  "#ffd173",
  "#ffd173",
  "#ffad66",
  "#dfbfff",
  "#ffad66",
  "#d5ff80",
  "#f28779",
  "#d5ff80",
  "#5ccfe6",
  "#73d0ff",
  "#95e6cb",
  "#dfbfff",
);

export function ayu_dark_class() {
  let _pipe = $sketch.class$(
    toList([
      $sketch.property("--background", ayu_dark.background),
      $sketch.property("--shadow", ayu_dark.shadow),
      $sketch.property("--primary", ayu_dark.primary),
      $sketch.property("--editor-fg", ayu_dark.editor_fg),
      $sketch.property("--editor-bg", ayu_dark.editor_bg),
      $sketch.property("--gutter", ayu_dark.gutter),
      $sketch.property("--syntax-comment", ayu_dark.syntax_comment),
      $sketch.property("--button", ayu_dark.button),
      $sketch.property("--function", ayu_dark.function),
      $sketch.property("--nil", ayu_dark.nil),
      $sketch.property("--bool", ayu_dark.bool),
      $sketch.property("--constant", ayu_dark.constant),
      $sketch.property("--bit-array", ayu_dark.bit_array),
      $sketch.property("--utfcodepoint", ayu_dark.utf_codepoint),
      $sketch.property("--string", ayu_dark.string),
      $sketch.property("--number", ayu_dark.number),
      $sketch.property("--custom-type", ayu_dark.custom_type),
      $sketch.property("--regex", ayu_dark.regex),
      $sketch.property("--date", ayu_dark.date),
    ]),
  );
  return $sketch.to_lustre(_pipe);
}

export const ayu_light = new Colors(
  "white",
  "#ccc",
  "#ffd596",
  "#5c6166",
  "#f8f9fa",
  "#8a919966",
  "#787b8099",
  "#F2AE49",
  "#F2AE49",
  "#fa8d3e",
  "#a37acc",
  "#fa8d3e",
  "#86b300",
  "#f07171",
  "#86b300",
  "#55b4d4",
  "#399ee6",
  "#4cbf43",
  "#a37acc",
);

export function ayu_light_class() {
  let _pipe = $sketch.class$(
    toList([
      $sketch.property("--background", ayu_light.background),
      $sketch.property("--shadow", ayu_light.shadow),
      $sketch.property("--primary", ayu_light.primary),
      $sketch.property("--editor-fg", ayu_light.editor_fg),
      $sketch.property("--editor-bg", ayu_light.editor_bg),
      $sketch.property("--gutter", ayu_light.gutter),
      $sketch.property("--syntax-comment", ayu_light.syntax_comment),
      $sketch.property("--button", ayu_light.button),
      $sketch.property("--function", ayu_light.function),
      $sketch.property("--nil", ayu_light.nil),
      $sketch.property("--bool", ayu_light.bool),
      $sketch.property("--constant", ayu_light.constant),
      $sketch.property("--bit-array", ayu_light.bit_array),
      $sketch.property("--utfcodepoint", ayu_light.utf_codepoint),
      $sketch.property("--string", ayu_light.string),
      $sketch.property("--number", ayu_light.number),
      $sketch.property("--custom-type", ayu_light.custom_type),
      $sketch.property("--regex", ayu_light.regex),
      $sketch.property("--date", ayu_light.date),
    ]),
  );
  return $sketch.to_lustre(_pipe);
}

export const catpuccin_light = new Colors(
  "#e6e9ef",
  "#dce0e8",
  "#dc8a78",
  "#4c4f69",
  "#eff1f5",
  "#dce0e8",
  "#6c6f85",
  "#dd7878",
  "#ea76cb",
  "#8839ef",
  "#d20f39",
  "#8839ef",
  "#40a02b",
  "#fe640b",
  "#40a02b",
  "#04a5e5",
  "#7287fd",
  "#179299",
  "#d20f39",
);

export function catpuccin_light_class() {
  let _pipe = $sketch.class$(
    toList([
      $sketch.property("--background", catpuccin_light.background),
      $sketch.property("--shadow", catpuccin_light.shadow),
      $sketch.property("--primary", catpuccin_light.primary),
      $sketch.property("--editor-fg", catpuccin_light.editor_fg),
      $sketch.property("--editor-bg", catpuccin_light.editor_bg),
      $sketch.property("--gutter", catpuccin_light.gutter),
      $sketch.property("--syntax-comment", catpuccin_light.syntax_comment),
      $sketch.property("--button", catpuccin_light.button),
      $sketch.property("--function", catpuccin_light.function),
      $sketch.property("--nil", catpuccin_light.nil),
      $sketch.property("--bool", catpuccin_light.bool),
      $sketch.property("--constant", catpuccin_light.constant),
      $sketch.property("--bit-array", catpuccin_light.bit_array),
      $sketch.property("--utfcodepoint", catpuccin_light.utf_codepoint),
      $sketch.property("--string", catpuccin_light.string),
      $sketch.property("--number", catpuccin_light.number),
      $sketch.property("--custom-type", catpuccin_light.custom_type),
      $sketch.property("--regex", catpuccin_light.regex),
      $sketch.property("--date", catpuccin_light.date),
    ]),
  );
  return $sketch.to_lustre(_pipe);
}

export const catpuccin_frappe = new Colors(
  "#292c3c",
  "#232634",
  "#f2d5cf",
  "#c6d0f5",
  "#303446",
  "#232634",
  "#a5adce",
  "#eebebe",
  "#f4b8e4",
  "#ca9ee6",
  "#e78284",
  "#ca9ee6",
  "#a6d189",
  "#ef9f76",
  "#a6d189",
  "#99d1db",
  "#babbf1",
  "#81c8be",
  "#e78284",
);

export function catpuccin_frappe_class() {
  let _pipe = $sketch.class$(
    toList([
      $sketch.property("--background", catpuccin_frappe.background),
      $sketch.property("--shadow", catpuccin_frappe.shadow),
      $sketch.property("--primary", catpuccin_frappe.primary),
      $sketch.property("--editor-fg", catpuccin_frappe.editor_fg),
      $sketch.property("--editor-bg", catpuccin_frappe.editor_bg),
      $sketch.property("--gutter", catpuccin_frappe.gutter),
      $sketch.property("--syntax-comment", catpuccin_frappe.syntax_comment),
      $sketch.property("--button", catpuccin_frappe.button),
      $sketch.property("--function", catpuccin_frappe.function),
      $sketch.property("--nil", catpuccin_frappe.nil),
      $sketch.property("--bool", catpuccin_frappe.bool),
      $sketch.property("--constant", catpuccin_frappe.constant),
      $sketch.property("--bit-array", catpuccin_frappe.bit_array),
      $sketch.property("--utfcodepoint", catpuccin_frappe.utf_codepoint),
      $sketch.property("--string", catpuccin_frappe.string),
      $sketch.property("--number", catpuccin_frappe.number),
      $sketch.property("--custom-type", catpuccin_frappe.custom_type),
      $sketch.property("--regex", catpuccin_frappe.regex),
      $sketch.property("--date", catpuccin_frappe.date),
    ]),
  );
  return $sketch.to_lustre(_pipe);
}

export const gleam = new Colors(
  "#2f2f2f",
  "#2f2f2f",
  "#ffaff3",
  "#fefefc",
  "#292d3e",
  "#8a919966",
  "#848484",
  "#ffaff3",
  "#ffd596",
  "#d4d4d4",
  "#ff6262",
  "#d4d4d4",
  "#c8ffa7",
  "#c8ffa7",
  "#c8ffa7",
  "#a6f0fc",
  "#9ce7ff",
  "#fdffab",
  "#ffddfa",
);

export function gleam_class() {
  let _pipe = $sketch.class$(
    toList([
      $sketch.property("--background", gleam.background),
      $sketch.property("--shadow", gleam.shadow),
      $sketch.property("--primary", gleam.primary),
      $sketch.property("--editor-fg", gleam.editor_fg),
      $sketch.property("--editor-bg", gleam.editor_bg),
      $sketch.property("--gutter", gleam.gutter),
      $sketch.property("--syntax-comment", gleam.syntax_comment),
      $sketch.property("--button", gleam.button),
      $sketch.property("--function", gleam.function),
      $sketch.property("--nil", gleam.nil),
      $sketch.property("--bool", gleam.bool),
      $sketch.property("--constant", gleam.constant),
      $sketch.property("--bit-array", gleam.bit_array),
      $sketch.property("--utfcodepoint", gleam.utf_codepoint),
      $sketch.property("--string", gleam.string),
      $sketch.property("--number", gleam.number),
      $sketch.property("--custom-type", gleam.custom_type),
      $sketch.property("--regex", gleam.regex),
      $sketch.property("--date", gleam.date),
    ]),
  );
  return $sketch.to_lustre(_pipe);
}

export function get_color_scheme_class(color_scheme) {
  if (color_scheme instanceof AyuLight) {
    return ayu_light_class();
  } else if (color_scheme instanceof AyuDark) {
    return ayu_dark_class();
  } else if (color_scheme instanceof CatpuccinLight) {
    return catpuccin_light_class();
  } else if (color_scheme instanceof CatpuccinFrappe) {
    return catpuccin_frappe_class();
  } else {
    return gleam_class();
  }
}
