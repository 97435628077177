/// <reference types="./setup.d.mts" />
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $lustre from "../../../lustre/lustre.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $runtime from "../../../lustre/lustre/internals/runtime.mjs";
import * as $document from "../../../plinth/plinth/browser/document.mjs";
import * as $element from "../../../plinth/plinth/browser/element.mjs";
import * as $shadow from "../../../plinth/plinth/browser/shadow.mjs";
import { addCustomStyles as add_custom_styles, updateLustre as update_lustre } from "../../tardis.ffi.mjs";
import * as $msg from "../../tardis/internals/data/msg.mjs";
import * as $stylesheet from "../../tardis/internals/stylesheet.mjs";
import { stylesheet } from "../../tardis/internals/stylesheet.mjs";

export { update_lustre };

export function instanciate_shadow_root(element) {
  let div = $document.create_element("div");
  let root = $shadow.attach_shadow(div, new $shadow.Open());
  $element.append_child($document.body(), div);
  $element.set_attribute(div, "class", "tardis");
  $shadow.append_child(root, element);
  return root;
}

export function instanciate_lustre_root() {
  let root = $document.create_element("div");
  $element.set_attribute(root, "id", "tardis-start");
  return root;
}

export function mount_shadow_node() {
  add_custom_styles(stylesheet);
  let lustre_root_ = instanciate_lustre_root();
  let shadow_root = instanciate_shadow_root(lustre_root_);
  let lustre_root = $dynamic.unsafe_coerce($dynamic.from(lustre_root_));
  return [shadow_root, lustre_root];
}

export function wrap_init(middleware) {
  return (init) => {
    return (flags) => {
      let new_state = init(flags);
      let _pipe = new_state;
      let _pipe$1 = $pair.first(_pipe);
      let _pipe$2 = $dynamic.from(_pipe$1);
      middleware(_pipe$2, $dynamic.from("Init"))
      return new_state;
    };
  };
}

export function wrap_update(middleware) {
  return (update) => {
    return (model, msg) => {
      let new_state = update(model, msg);
      let _pipe = new_state;
      let _pipe$1 = $pair.first(_pipe);
      let _pipe$2 = $dynamic.from(_pipe$1);
      middleware(_pipe$2, $dynamic.from(msg))
      return new_state;
    };
  };
}

export function create_model_updater(dispatch, application) {
  return (dispatcher) => {
    let _pipe = (model) => {
      return $effect.from(
        (_) => {
          let _pipe = model;
          let _pipe$1 = $dynamic.from(_pipe);
          let _pipe$2 = new $runtime.ForceModel(_pipe$1);
          let _pipe$3 = new $runtime.Debug(_pipe$2);
          return $dynamic.unsafe_coerce(dispatcher)(_pipe$3);
        },
      );
    };
    let _pipe$1 = ((_capture) => {
      return new $msg.AddApplication(application, _capture);
    })(_pipe);
    let _pipe$2 = $lustre.dispatch(_pipe$1);
    return dispatch(_pipe$2);
  };
}

export function step_adder(dispatch, name) {
  return (model, msg) => {
    let _pipe = model;
    let _pipe$1 = ((_capture) => {
      return new $msg.AddStep(name, _capture, msg);
    })(_pipe);
    let _pipe$2 = $lustre.dispatch(_pipe$1);
    return dispatch(_pipe$2);
  };
}
