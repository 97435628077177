/// <reference types="./model.d.mts" />
import { CustomType as $CustomType } from "../gleam.mjs";

export class Model extends $CustomType {
  constructor(count) {
    super();
    this.count = count;
  }
}

export function new$() {
  return new Model(0);
}
